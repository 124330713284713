import { useBookedTickets } from '../../../shared/m-anage';
import { getSettings } from './utils';

const ticketByType = (ticketTypeName) => (el) => el.TicketTypeName === ticketTypeName;

export const useHasTicket = () => {
  const resp = useBookedTickets();
  const { passThroughTicketTypeName: ticketTypeName } = getSettings();

  return ({
    ...resp,
    // strict equality to true is important, since the result of finding ticket type can be also undefined
    // This way we only assume boolean value.
    data: resp.data?.some(ticketByType(ticketTypeName)) === true
  });
};
