import { emptyNull } from './null-pattern';

export const pipe = (...fns) => (x) => fns.reduce((y, f) => f(y), x);
export const pipeAny = (...fns) => (x) => fns.some((f) => f(x));
export const takeProp = (k) => (o) => o[k];
export const localStorageGetItem = (k) => localStorage.getItem(k);
export const identity = (el) => el;

export const overwriteValues = (defaultObj = emptyNull) => (newValues = emptyNull) => Object.keys(defaultObj).reduce(
  (acc, key) => ({
    ...acc,
    [key]: newValues[key] ?? defaultObj[key]
  }),
  {}
);
