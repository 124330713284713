/* This schema represents all possible m-anage API parametrization.
not all of them need to be present, hance partial() is applied.
*/
import { z } from 'zod';

export default z.object({
  eventGroups: z.string(),
  baseUrl: z.string(),
  bearerToken: z.string(),
  brightSpaceProfile_id: z.string(),
  companyAccountId: z.string(),
  envAuthToken: z.string(),
  envClassicToken: z.string(),
  envClassicTokenPlain: z.string(),
  envJWTToken: z.string(),
  envJWTTokenJMEServices: z.string(),
  envJWTTokenLionLEADS: z.string(),
  envJWTTokenLionLeads: z.string(),
  envJWTTokenMTalkExport: z.string(),
  envJWTTokenPlain: z.string(),
  envJWTTokenPresentationAccess: z.string(),
  envJWTTokenSessionAccess: z.string(),
  envJWTTokenThirdParty: z.string(),
  envLoginEmail: z.string(),
  envLoginPassword: z.string(),
  eventId: z.string(),
  eventShort: z.string(),
  hostApi: z.string().url(),
  hostApiv2: z.string(),
  hostIdentity: z.string(),
  hostOAuth: z.string(),
  oAuthClientId: z.string(),
  oAuthClientSecret: z.string(),
  oAuthScope: z.string(),
  societyShort: z.string(),
}).partial();
