import { lazy } from 'react';
// Others
export const DateFormatter = lazy(() => import('./DateFormatter'));
export const NavigationManager = lazy(() => import('./navigationManager/NavigationManager'));
export const MobileNavigation = lazy(() => import('./mobileNavigation/MobileNavigation'));
export const ResourceImagePreview = lazy(() => import('./ResourceImagePreview'));
export const JMESendMessageToAuthor = lazy(() => import('./JmeSendMessageToAuthor'));
export const CourseImage = lazy(() => import('./CourseImage'));
export const FilterTypeContent = lazy(() => import('./FilterTypeContent'));
export const TimeDiffDuration = lazy(() => import('./TimeDiffDuration'));
export const JmeVideoPlayer = lazy(() => import('./JmeVideoPlayer'));
export const ResourcesFiltering = lazy(() => import('./ResourcesFiltering'));
export const FirstAvailiablePresentation = lazy(() => import('./FirstAvailiablePresentation'));
export const MediaMetaInfo = lazy(() => import('./MediaMetaInfo'));
export const JmeNavbar = lazy(() => import('./JmeNavbar'));
export const ShowMore = lazy(() => import('./ShowMore'));
export const JmeQnaContainer = lazy(() => import('./JmeQnaContainer'));
export const PigeonMap = lazy(() => import('./PigeonMap'));
export const GoogleMapsComp = lazy(() => import('./GoogleMapsComp'));
export const MapsComponent = lazy(() => import('./MapsComponent'));
export const JmeFormInputAndSubmitActive = lazy(() => import('./JmeFormInputAndSubmitActive'));
export const Youtube = lazy(() => import('./Youtube'));
export const JmeModal = lazy(() => import('./JmeModal'));
export const ShareButton = lazy(() => import('./ShareButton'));
export const LiveStreamComponent = lazy(() => import('./LiveStreamComponent'));
export const MyContent = lazy(() => import('./MyContent'));
export const JMTDoiDirectory = lazy(() => import('./JMTDoiDirectory'));
export const JMTMembersDirectory = lazy(() => import('./JMTMembersDirectory'));
export const JMTGeneralAssembly = lazy(() => import('./JMTGeneralAssembly'));
export const JMTMembershipApplication = lazy(() => import('./JMTMembershipApplication'));
export const JMTCustomQueries = lazy(() => import('./JMTCustomQueries'));
export const JMTDoiNotification = lazy(() => import('./JMTDoiNotification'));
export const JMTMyDocuments = lazy(() => import('./JMTMyDocuments'));
export const JMTDoiForm = lazy(() => import('./JMTDoiForm'));
export const JMTMyProfile = lazy(() => import('./JMTMyProfile'));
export const UserProfile = lazy(() => import('./UserProfile'));
export const Programme = lazy(() => import('./Programme'));
export const VmSwitch = lazy(() => import('./VmSwitch'));
export const GalleryPreview = lazy(() => import('./galleryPreview/GalleryPreview'));
export const GalleryPreviewDynamic = lazy(() => import('./galleryPreview/GalleryPreviewDynamic'));
export const MTV = lazy(() => import('./MTV'));
export const LeadButtons = lazy(() => import('./LeadButtons'));
export const LikeButton = lazy(() => import('./LikeButton'));

export const Ticker = lazy(() => import('./Ticker'));

export const VoucherSubmission = lazy(() => import('./voucher/VoucherSubmission'));

export const BreakpointBox = lazy(() => import('./BreakpointBox'));
export {
  Init as MembershipOverviewInit,
  MembershipOverview as JMTMembershipOverview,
  JMTMembershipBenefits
} from '../../modules/membership-overview';
export { default as JmeOnClick } from './JmeOnClick';
export { default as IsUserLogged } from './IsUserLogged';
export { default as JmeSlider } from './JmeSlider';
export { default as JmeSidePopup } from './JmeSidePopup';
export const HeadTags = lazy(() => import('./HeadTags'));
export const SocialWall = lazy(() => import('./SocialWall'));
