import store from '../../../global-state/redux';
import { MODULE_NAME } from './constants';
import { selectIsActive, selectSettings } from '../../hooks';

const { getState } = store;

export const getIsActive = () => selectIsActive(MODULE_NAME)(getState());

export const signalRUrl = () => selectSettings(MODULE_NAME)(getState())?.signalRUrl;

export const openAccountHubModal = () => selectSettings(MODULE_NAME)(getState())?.openAccountHubModal;

export const accountHubConnectionAmount = () => selectSettings(MODULE_NAME)(getState())?.accountHubConnectionAmount;

export const modalTitle = () => selectSettings(MODULE_NAME)(getState())?.modalTitle;

export const modalDescription = () => selectSettings(MODULE_NAME)(getState())?.modalDescription;

export const modalContinueTxt = () => selectSettings(MODULE_NAME)(getState())?.modalContinueTxt;

export const modalLogoutTxt = () => selectSettings(MODULE_NAME)(getState())?.modalLogoutTxt;

export const backgroundImage = () => selectSettings(MODULE_NAME)(getState())?.backgroundImage;
