import { isArr, isStr } from '../../global-utils';

export const checkReservedUrl = (url, reservedUrls) => {
  if (!isStr(url) || !isArr(reservedUrls)) return false;

  return reservedUrls.some((el) => new RegExp(el).test(url));
};

export const canBuildeRequest = (config) => {
  const { accessToken, eventShort, apiBaseUrl } = config ?? {};

  let canParse;
  // Browser  Safari 16.X doesnt support URL.canParse
  if (typeof URL.canParse === 'function') {
    canParse = URL.canParse(apiBaseUrl);
  } else {
    // Fallback: try-catch for older Safari
    try {
      canParse = new URL(apiBaseUrl);
      // use url...
    } catch (e) {
      canParse = false;
    }
  }

  return accessToken && eventShort && canParse;
};

export const buildRequest = (config) => {
  const {
    accessToken, short, role, context, apiBaseUrl, WithCQCheck
  } = config;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      context, shortName: short, role, WithCQCheck
    })
  };

  const url = new URL(apiBaseUrl);
  url.pathname = '/v2/Core/CheckPermissionsForRole';
  return new Request(url, options);
};
