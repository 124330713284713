import { pipeAny, pipe } from '../../../shared/misc';
import { MODULE_NAME } from './constants';
import applyDefaults from './apply-defaults';
import { getSettingsById } from '../../utils';

const checkPassThroughTimeWindow = (now) => (cfg) => cfg.passThroughStartsAt <= now && now <= cfg.passThroughEndsAt;
const checksPassThroughUrl = (url) => (cfg) => new RegExp(cfg.passThroughUrl, 'i').test(url);
const checkPassThroughInPreview = (isPreview) => (cfg) => cfg.enableInPreview && isPreview;
const checkPassThroughInAppContainer = (isAppContainer) => (cfg) => cfg.enableInAppContainer && isAppContainer;

export const checkPassThrough = ({ isPreview, isAppContainer, url }) => pipeAny(
  checkPassThroughTimeWindow(Date.now()),
  checksPassThroughUrl(url),
  checkPassThroughInPreview(isPreview),
  checkPassThroughInAppContainer(isAppContainer)
);

export const getSettings = () => pipe(getSettingsById, applyDefaults)(MODULE_NAME);
