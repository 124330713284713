import {
  LOGIN_USER,
  LOGOUT_USER,
} from '../constants';

function deleteAllCookies() {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  }
}

export const loginUser = (user) => ({ type: LOGIN_USER, payload: user });

export const logoutUser = () => {
  deleteAllCookies();
  localStorage.clear();
  return { type: LOGOUT_USER, payload: undefined };
};
