import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignalRContext } from './SignalRContext';
import {
  useSettingsWithManage,
} from '../../manage-config';
import {
  getIsActive,
  signalRUrl,
  openAccountHubModal,
  accountHubConnectionAmount,
  modalTitle,
  modalDescription,
  modalContinueTxt,
  modalLogoutTxt,
  backgroundImage
} from '../model/utils';
import SignalRLogoutModal from './SignalRLogoutModal';
import storageAndCookie from '../../../global-utils/storage-and-cookies';
import { blockUser } from '../api/api';
import { emptyNull } from '../../../shared/misc';

const generateUuid = () => `uuid-${Math.random().toString(36).slice(2, 9)}`; // Simple UUID generation

// SignalR provider component to wrap the app and manage connection state
export const SignalRProvider = ({ children }) => {
  const navigate = useNavigate();
  const [uuid, setUuid] = useState(storageAndCookie.get('signal-r-logout-uuid') || null); // UUID for connection
  const [persist, setPersist] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [connectEnabled, setConnectEnabled] = useState(false); // Control connection state

  const moduleSettings = useSettingsWithManage('signalR') ?? emptyNull;

  const {
    eventShort,
    accessToken,
  } = moduleSettings;
  
  const isActive = getIsActive();

  // Handle "logout-user-device" action
  const logoutUserDevice = () => {
    setPersist(false);
    setIsModalOpen(false); // Close modal
    localStorage.removeItem('signal-r-logout-uuid'); // Remove UUID from storage
    setUuid(null); // Reset UUID state
    console.log('Logging out user...');
    navigate('/logout', { replace: true });
  };

  // Handle "continue-user-device" action
  const continueUserDevice = () => {
    setPersist(true);
    setIsModalOpen(false); // Close modal

    console.log('Continuing user session...');
  };

  // Generate UUID & Enable Connection on Login
  useEffect(() => {
    if (accessToken && isActive) {
      setConnectEnabled(true); // Enable SignalR when JWT is valid
      if (!uuid) {
        const newUuid = generateUuid();
        setUuid(newUuid);
        storageAndCookie.set('signal-r-logout-uuid', newUuid);
      }
    } else {
      setConnectEnabled(false); // Disable SignalR when no JWT
    }
  }, [accessToken, isActive, uuid]);

  SignalRContext.useSignalREffect('remoteLogout', async (params) => {
    console.log('Received remote logout event');
    if (!params?.uuid || params?.modal == null) {
      console.log('Invalid parameters received. Ignoring logout event.');
      return;
    }

    if (openAccountHubModal() && params.uuid === uuid && params.modal) {
      console.log('Triggering modal for remote logout...');
      setIsModalOpen(true);
    } else if (openAccountHubModal()) {
      console.log('Blocking user and logging out...');
      blockUser();
      navigate('/logout', { replace: true });
    } else {
      console.log('Logging out...');
      navigate('/logout', { replace: true });
    }

    // Stop the SignalR connection if no active connections
    if (accountHubConnectionAmount() === 0) {
      console.log('No active SignalR connections. Stopping connection...');
      setConnectEnabled(false);
    }
  });
  const signalRHubUrl = `${signalRUrl()}/singlelogin?eventShort=${eventShort}&jwt=${accessToken}&intmodal=${accountHubConnectionAmount()}&persist=${persist}&uuid=${uuid}`;

  if (!accessToken || !isActive || !uuid) {
    return children; // Render children without SignalR if conditions are not met
  }


  return (
    <SignalRContext.Provider
      connectEnabled={connectEnabled}
      dependencies={[accessToken, uuid, persist]} // Reconnect if jwt or uuid changes
      value={{ setPersist }} // Provide setPersist function and messages to child components
      url={signalRHubUrl}
    >
      {isModalOpen ? (
        <SignalRLogoutModal
          open={isModalOpen}
          title={modalTitle()}
          description={modalDescription()}
          continueTxt={modalContinueTxt()}
          logoutTxt={modalLogoutTxt()}
          backgroundImage={backgroundImage()}
          onClose={() => setIsModalOpen(false)}
          onContinue={continueUserDevice}
          onLogout={logoutUserDevice}
        />
      ) : (
        children
      )}
    </SignalRContext.Provider>
  );
};
