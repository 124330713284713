import manageSchema from '../model/schema';
import { assertOk, takeData } from '../../misc/api-utils';
import { authFactory, urlApiFactory } from '../model/factory';

export const PATHNAME = '/v2/Membership/MemberValid';

export const schema = manageSchema.pick({
  envJWTToken: true,
  hostApi: true,
  societyShort: true,
}).required();

export const fetchGet = (params) => async () => {
  const prms = schema.parse(params);
  const url = urlApiFactory(prms);
  url.pathname = PATHNAME;
  url.searchParams.append('societyShort', prms.societyShort);
  const headers = {
    Authorization: authFactory(prms)
  };

  const req = new Request(url, { headers });
  return fetch(req)
    .then(assertOk)
    .then(takeData);
};
