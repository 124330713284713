import store from '../global-state/redux';
import { emptyNull } from '../shared/misc';

const { getState } = store;

export const getModuleById = (moduleId) => getState()?.appState.settings?.modules?.[moduleId];
export const isActiveById = (moduleId) => !!getModuleById(moduleId)?.isActive;
export const getSettingsById = (moduleId) => getModuleById(moduleId)?.settings ?? emptyNull;

export const withIsActiveGuard = (id) => (Component) => (props) => {
  const isActive = isActiveById(id);

  if (isActive) return <Component {...props} />;
  const { children } = props;
  return children;
};
