import { useEffect } from 'react';
import { useLoginInfo } from '../../../global-utils';
import { useConfig, useDIT, useIsActive } from '../model/hooks';
import { dataInsightInit, extendUser } from '../model/utils';
import { setConfig } from '../model/state';
import {
  useIsActive as useIsManageConfigActive,
  useSettings as useManageConfig
} from '../../manage-config';
import { apiExtendUserDetails } from '../api/api';
import { fetchTicket } from '../../access-control/api/api';
import { getIsAdminPreview } from '../../../shared/admin-preview';

const Init = () => {
  // session user:
  const userLoginInfo = useLoginInfo();
  // m-anage params:
  const manageConfig = useManageConfig();
  const { accessToken } = manageConfig ?? {};
  const isManageConfigActive = useIsManageConfigActive();
  // data-insight params:
  const dit = useDIT();
  const isActive = useIsActive();
  const moduleConfig = useConfig();

  const canInit = Boolean(
    !getIsAdminPreview() // preview in iFrame
      && isActive // module is active
      && moduleConfig?.authKey // must have authKey
      && dit // module JS files finished loading
  );

  useEffect(() => {
    // Module functions have to have access to data-insight config
    // This save JSON config to the local state. It is O to do so,
    // coz module config is a static value.
    setConfig(moduleConfig);
  }, [moduleConfig]);

  // To initialize data-insight, we need some additional information about user:
  // 1. manage config params (accessToken, apiBaseUrl, eventId)
  // 2. user info (city, countryCode, manageUserId)
  useEffect(() => {
    if (canInit) {
      const promiseList = [];

      if (accessToken && isManageConfigActive) promiseList.push(fetchTicket(manageConfig));
      if (isManageConfigActive) promiseList.push(apiExtendUserDetails(manageConfig));

      if (accessToken) {
        Promise.all(promiseList)
          .then(extendUser(userLoginInfo))
          .then(dataInsightInit);
      } else {
        dataInsightInit();
      }
    }
  }, [userLoginInfo, isManageConfigActive, manageConfig, canInit, accessToken]);

  return null;
};

export default Init;
