import { Loading } from '../../../components';
import { useIsUserLoggedIn } from '../../../global-utils';
import { isInsideAppContainer } from '../../../global-utils/vmFunctions/others';
import { getIsAdminPreview } from '../../../shared/admin-preview';
import { withIsActiveGuard } from '../../utils';
import { MODULE_NAME } from '../model/constants';
import { useHasTicket } from '../model/hook';
import { checkPassThrough, getSettings } from '../model/utils';
import RestrictedAccess from './RestrictedAccess';

const HoldingPageGuard = ({ children, url, ...restProps }) => {
  const hasTicket = useHasTicket();
  const isLoggedIn = useIsUserLoggedIn();
  const isPreview = getIsAdminPreview();
  const isAppContainer = isInsideAppContainer();
  const config = getSettings();
  const canPassThrough = checkPassThrough({ isPreview, isAppContainer, url })(
    config
  );

  // Order matters!
  if (canPassThrough) return children;
  if (!isLoggedIn) return <RestrictedAccess {...restProps} />;
  if (hasTicket.error) return <Loading />;
  if (hasTicket.isLoading) return <Loading />;
  if (hasTicket.data) return children;

  return <RestrictedAccess {...restProps} />;
};

export default withIsActiveGuard(MODULE_NAME)(HoldingPageGuard);
