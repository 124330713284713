import { useCallback } from 'react';
import { useIsUserLoggedIn } from '../../../../global-utils';
import { useMemberValid } from '../../../../shared/m-anage';
import { checkIfUrlIsRestricted } from './utils';

export const useCanAccessUrl = () => {
  const isNotLoggedIn = !useIsUserLoggedIn();
  const { data, error } = useMemberValid();

  const ret = useCallback(
    (url) => {
      const isNotRestrictedUrl = !checkIfUrlIsRestricted(url);

      // IMPORTANT order matters
      if (isNotRestrictedUrl) return true;
      if (isNotLoggedIn) return false; // must be logged in to access restricted area
      if (error) return false; // whatever error is do not let access page
      if (data === true) return true;

      return false;
    },
    [isNotLoggedIn, error, data]
  );

  return ret;
};
