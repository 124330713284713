import { useSelector } from 'react-redux';
import { isUserLoggedIn } from '../global-utils';
import { mapData } from '../global-utils/dataMapping';
import { isArr } from '../global-utils/globalUtils';
import {
  hasOldPlaceholders,
  replacePlaceholders
} from '../global-utils/map-data-to-props';

const resolveShowIf = (showIf, dataObj) => {
  // Determines if to show a component

  // this might appear inintuitive, but on undefined when if showIf was not provided, show component
  if (showIf === undefined) return true;
  if (!isArr(showIf)) return false;
  if (showIf.length === 0) return false;

  return hasOldPlaceholders(showIf)
    ? showIf.every((el) => replacePlaceholders(el, dataObj))
    : mapData(dataObj, showIf, false).every((el) => !!el);
};
const resolveHideIf = (hideIf, dataObj) => {
  // this might appear inintuitive, but on undefined when if hideIf was not provided, show component
  if (!isArr(hideIf) || !hideIf.length) return false;

  return hasOldPlaceholders(hideIf)
    ? hideIf.some((el) => replacePlaceholders(el, dataObj))
    : hideIf.some((el) => mapData(dataObj, el, false));
};

/* eslint no-bitwise: ["error", { "allow": ["&", ">>"] }] */
const hex2rgb = (hexStr) => {
  // Warning: do not work with shortened version of css color as #FFF for white
  const hex = parseInt(hexStr.substring(1), 16);
  const r = (hex & 0xff0000) >> 16;
  const g = (hex & 0x00ff00) >> 8;
  const b = hex & 0x0000ff;
  return [r, g, b];
};

const setContrast = (hex = '#1D428A', diverge = null) => {
  // If hex is invalid hex-color string return black:
  if (!/^#[0-9A-Fa-f]{6}$/.test(hex)) {
    // console.error(`setContrast: Invalid color definition. Expeced hex but got ${hex}. Returning color black.`);
    return '#000000';
  }
  const [r, g, b] = hex2rgb(hex);
  const o = Math.round(
    (parseInt(r, 10) * 299 + parseInt(g, 10) * 587 + parseInt(b, 10) * 114)
      / 1000
  );
  return o > 125 ? '#000000' : diverge || '#FFFFFF';
};

const isProtectedUrl = (urlToCheck, state) => {
  const isUserLogged = isUserLoggedIn(state);
  const { mustLoginUrls = [] } = state?.appState?.settings?.loggedInOnly ?? {};

  // Tested on https://www.calculators.tech/boolean-algebra-calculator
  const urlToCheckToLowerCase = urlToCheck?.toLowerCase();
  return !isUserLogged && mustLoginUrls?.some((el) => {
    const pageUrlToLowerCase = el?.pageUrl?.toLowerCase();
    return el?.isExactPath
      ? pageUrlToLowerCase === urlToCheckToLowerCase
      : new RegExp(pageUrlToLowerCase).test(urlToCheckToLowerCase);
  });
};

const isProtectedUrlVoucher = (urlToCheck, state) => {
  const storedVoucher = localStorage.getItem('mEventsVoucher');
  const { protectedUrls = [], voucher } = state?.appState?.settings?.voucherOnly ?? {};

  const urlToCheckToLowerCase = urlToCheck?.toLowerCase();
  return voucher !== storedVoucher && protectedUrls?.some((el) => {
    const pageUrlToLowerCase = el?.pageUrl?.toLowerCase();
    return el?.isExactPath
      ? pageUrlToLowerCase === urlToCheckToLowerCase
      : new RegExp(pageUrlToLowerCase).test(urlToCheckToLowerCase);
  });
};

export const useIsProtectedUrl = () => {
  const state = useSelector((s) => s);
  return (urlToCheck) => isProtectedUrl(urlToCheck, state);
};
export const useIsProtectedUrlVoucher = () => {
  const state = useSelector((s) => s);
  return (urlToCheck) => isProtectedUrlVoucher(urlToCheck, state);
};

export {
  resolveShowIf, resolveHideIf, setContrast, isProtectedUrl, isProtectedUrlVoucher,
};
