import ComponentsArrRenderer from '../../../components/core/ComponentsArrRenderer';
import { emptyArray } from '../../../shared/misc';
import { getSettings } from '../model/utils';

export default (props) => {
  const { components = emptyArray } = getSettings();

  return (
    <ComponentsArrRenderer
      {...props}
      components={components}
      parent={['PAGE']}
    />
  );
};
