import LZString from 'lz-string';
import { navigate } from '../../../global-utils/vmFunctions/navigate';
import { handleHardReload } from '../../../components/AppWrapper';
import { vmRefProcessorApp } from '../../../global-utils';
import { loadSingleModules } from '../../../global-utils/loadModules';
import {
  APP_MOBILE_MENU_OPEN,
  APP_MOBILE_MENU_CLOSE,
  APP_MOBILE_MENU_TOGGLE,
  APP_TOPNAV_MENU_OPEN,
  APP_TOPNAV_MENU_CLOSE,
  APP_SET_READY,
  APP_SET_READY_ERROR,
  APP_SET_CORE,
  APP_CHANGE_PAGE,
  APP_CHANGE_BREAKPOINT,
  SET_CURRENT_LOCATION,
  SET_TEST_FLIGHT_PAGE,
  SET_TEST_FLIGHT_PAGE_PARAMS,
  SET_TEST_FLIGHT_COMPONENTS_BUILDING,
  SET_GRID_WITH_SIDE_PREVIEW,
  SET_CLIENT_DATA,
  MANUAL_UPDATE_CLIENT_DATA,
  USER_AUTH_LOADING_START,
  USER_AUTH_LOADING_END,
  SET_USER_IS_ONLINE,
  SET_USER_IS_OFFLINE,
  SET_MY_NETWORKING,
  FORCE_CLOSE_SPLASH_SCREEN,
  SET_LAYER,
  SET_VOUCHER
} from '../constants';
import { apiRequest } from '../../../global-utils/api-request';
import { loginUser } from '.';
import storageAndCookie from '../../../global-utils/storage-and-cookies';
import { isArr, isObjKey } from '../../../global-utils/globalUtils';
import { getIsAdminPreview } from '../../../shared/admin-preview';
import manage from '../../../shared/m-anage';
import { emptyNull, identity } from '../../../shared/misc';

export const setAppOnline = () => ({ type: SET_USER_IS_ONLINE });
export const setAppOffline = () => ({ type: SET_USER_IS_OFFLINE });

export const setAppVoucher = (voucher) => ({ type: SET_VOUCHER, payload: voucher });
export const setCurrentAppLocation = (loc) => ({
  type: SET_CURRENT_LOCATION,
  payload: loc
});
export const openMobileMenu = () => ({
  type: APP_MOBILE_MENU_OPEN,
  payload: {}
});
export const closeMobileMenu = () => ({
  type: APP_MOBILE_MENU_CLOSE,
  payload: {}
});
export const toggleMobileMenu = () => ({
  type: APP_MOBILE_MENU_TOGGLE,
  payload: {}
});
export const openTopNavMenu = (topNavMenuAnchor) => ({
  type: APP_TOPNAV_MENU_OPEN,
  payload: { topNavMenuAnchor }
});
export const closeTopNavMenu = () => ({
  type: APP_TOPNAV_MENU_CLOSE,
  payload: {}
});

export const setAppReady = (isReady, settings) => (dispatch) => {
  const moduleList = Object.values(settings.modules ?? emptyNull)
    .filter((el) => el.isActive);

  manage.configure(settings.modules?.manageConfig?.settings);

  // First - load modules on page load only needed on page load
  moduleList.forEach((el) => loadSingleModules(el, false));
  const allCompsToCore = moduleList
    .flatMap((el) => el.componentsToCore)
    .filter(identity);

  // Reference library is packed in the settings.
  // Resolving references within vmRefLib is taken care
  // of inside the updateDefinition function. This is why
  // we destruct it and pass only restSettings into the
  // recolve function.
  const { vmRefLib, ...restSettings } = settings;
  const settingsVmRefResolved = vmRefProcessorApp
    .updateDefinitions(vmRefLib)
    .resolve(restSettings);

  // Place reference-resolved settings into redux
  dispatch({
    type: APP_SET_READY,
    payload: { settings: settingsVmRefResolved, isReady, allCompsToCore }
  });
};

export const setPagePreview = (settings) => ({
  type: SET_TEST_FLIGHT_PAGE,
  payload: settings
});
export const setPageParams = (pageParams) => ({
  type: SET_TEST_FLIGHT_PAGE_PARAMS,
  payload: pageParams
});
export const setComponentsBuildingPreview = (settings) => ({
  type: SET_TEST_FLIGHT_COMPONENTS_BUILDING,
  payload: settings
});
export const setAppReadyError = (isReady) => ({
  type: APP_SET_READY_ERROR,
  payload: isReady
});
export const setCoreData = (coreData) => ({
  type: APP_SET_CORE,
  payload: coreData
});

export const changeCurrentPageInfo = (title, pageName) => ({
  type: APP_CHANGE_PAGE,
  payload: { title, pageName }
});

export const changeCurrentBreakpoint = (breakpoint) => ({
  type: APP_CHANGE_BREAKPOINT,
  payload: breakpoint
});

export const setGridWithSidePreview = (isActive, content) => ({
  type: SET_GRID_WITH_SIDE_PREVIEW,
  payload: { isActive, content }
});

const gotSettingsJson = (res) => (dispatch) => {
  const val = res?.[0]?.value;
  const decompressedVal = LZString.decompressFromBase64(val);
  const currentSettings = JSON.parse(decompressedVal);
  if (currentSettings) {
    dispatch(setAppReady(true, currentSettings));
  } else {
    console.log('No translations?', res.data);
    dispatch(setAppReadyError());
  }
};

const getSettingsFromApi = (coreSettings) => (dispatch) => {
  if (
    coreSettings.cms_settings_types_id
    || coreSettings.cms_settingtype_id
    || coreSettings.cms_settingtype_title
  ) {
    // alert(`I AM HERE${coreSettings.info_translation_key}`);
    const url = `https://${coreSettings.server_addr}/v1/cms_settings.json`;
    const body = {
      page: 1,
      per_page: 1, // page 1 and per_page 1 means we bring the last saved settings
      app_id: coreSettings.app_ids,
      include_value: 1,
      is_preproduction: storageAndCookie.get(`JmeENV_${coreSettings.event_id}`)
        ? 1
        : 0
    };
    // by default - if we know the settings type id
    // then get the id. It's better to use just the title
    if (coreSettings.cms_settings_types_id) {
      body.cms_settingtype_id = coreSettings.cms_settings_types_id;
    } else if (coreSettings.cms_settingtype_id) {
      body.cms_settingtype_id = coreSettings.cms_settingtype_id;
    } else {
      // else - use the title. it's better -
      // because the ids may change between production and staging
      body.cms_settingtype_title = coreSettings.cms_settingtype_title;
    }
    apiRequest({ url, body }).then((res) => {
      if (res.data?.length === 1) {
        dispatch(gotSettingsJson(res.data));
      }
      if (res?.data?.error || res?.data?.error_code || res.data.error_message) {
        console.error('Translations error', res);
        dispatch(setAppReadyError());
      }
    });
  } else {
    dispatch(setAppReadyError());
  }
};

export const getCurrentAppSettings = (coreSettings) => (dispatch) => {
  // first set Core:
  if (window.location.hash === '#staging') {
    storageAndCookie.set(`JmeENV_${coreSettings.event_id}`, 'true');
    // https://stackoverflow.com/questions/1397329/how-to-remove-the-hash-from-window-location-url-with-javascript-without-page-r/5298684#5298684
    window.history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    );
  }

  // info_file_path_dev
  // GO TO CHEN with settings_type_id
  // info_file_path

  dispatch(setCoreData(coreSettings));
  if (coreSettings.info_file_path_dev) {
    let infoFilePath = coreSettings.info_file_path_dev;

    if (infoFilePath.indexOf('http') === 0 - 1) {
      // There is no full path for the settings, use current
      // infoFilePath = `${window.location.origin}/${coreSettings.info_file_path_dev}?v=${Math.random()}`;
      infoFilePath = `${window.location.origin}/${coreSettings.info_file_path_dev}`;
    }

    fetch(infoFilePath)
      .then((res) => res.json())
      .then((res) => {
        // Now we got the whole structure of our app :-)
        // res.appName is LEGACY;
        document.title = res?.appSettings?.appTitle || res.appName;
        // @NODE_ENV: Replace place holders in layoutSetting with content from json component's files:
        dispatch(setAppReady(true, res));
        // dispatch(setAppReady(true, res));
      })
      .catch((e) => {
        console.error(
          'HUGE ERROR ! We didnt get the settings for this VM using info_file_path_dev',
          e
        );
        // TODO: Check if we're on development env and trigger temp
      });
  } else if (coreSettings.info_file_path) {
    let infoFilePath = coreSettings.info_file_path;
    if (
      storageAndCookie.get(`JmeENV_${coreSettings.event_id}`) // if staging/pre-production mode is on :)
      && coreSettings.info_file_path_staging
    ) {
      infoFilePath = coreSettings.info_file_path_staging;
    }
    if (infoFilePath.indexOf('http') === 0 - 1) {
      // There is no full path for the settings, use current
      // infoFilePath = `${window.location.origin}/${coreSettings.info_file_path}?v=${Math.random()}`;
      infoFilePath = `${window.location.origin}/${coreSettings.info_file_path}`;
    }

    const noCacheHeaders = new Headers();
    noCacheHeaders.append('pragma', 'no-cache');
    noCacheHeaders.append('cache-control', 'no-cache');
    fetch(`${infoFilePath}`, {
      cache: 'no-cache',
      headers: noCacheHeaders,
      mode: 'cors'
    })
      .then((res) => res.json())
      .then((res) => {
        // Now we got the whole structure of our app :-)
        console.log('I GOT HERE SOMETHING ?');
        dispatch(gotSettingsJson([res]));
      })
      .catch((e) => {
        console.error(
          'HUGE ERROR ! We didnt get the settings for this VM using info_file_path',
          e
        );
        dispatch(getSettingsFromApi(coreSettings));
        // TODO: Check if we're on development env and trigger temp
      });
  } else {
    // I change that default, should go and ask the API for the settings.
    dispatch(getSettingsFromApi(coreSettings));
  }
};

export const prepareApp = () => (dispatch) => {
  const coreSettingsFileName = window.vmRevolutionCorePath || 'coreSettings.json';

  if (typeof window.vmRevolutionCoreValue === 'object') {
    dispatch(getCurrentAppSettings(window.vmRevolutionCoreValue));
  } else if (!getIsAdminPreview()) {
    const filePath = `${window.location.origin}/${coreSettingsFileName}`;
    fetch(filePath)
      .then((res) => res.json())
      .then((incomingCoreSettings) => {
        dispatch(getCurrentAppSettings(incomingCoreSettings));
      })
      .catch((e) => {
        console.log('HUGE ERROR ! NO SETTINGS FILE.. !! ', e);
        dispatch(setAppReadyError());
      });
  }
};

export const ocaLogin = ({ uid, accessToken }) => (dispatch, getState) => {
  const state = getState();
  const { instance } = state.appState.settings?.modules?.loginToJMTModule?.settings ?? {};

  const instanceUser = `${instance}.user`;

  const url = `https://${state?.appState?.core.server_addr}/v1/logins/${uid}.json`;
  const body = {
    access_token: accessToken,
    include_user: 1,
    include_grants: 1,
    include_manage_jwt_token: 1
  };
  apiRequest({ url, body })
    .then((res) => {
      if (!res?.data?.error_code && !res?.data?.error_message) {
        dispatch(loginUser(res.data));
        // Keep user info in cookies:
        if (res.data.manage_jwt_token) {
          storageAndCookie.set(
            instanceUser,
            JSON.stringify({ accessToken: res.data.manage_jwt_token })
          );
        }
        storageAndCookie.set(
          'access_token_all',
          JSON.stringify(res.data),
          new Date(res.data?.expires_at),
          true
        );
        handleHardReload();
        dispatch(navigate('/'));
      }
    })
    .catch((err) => console.error('ocaLogin err ', err));
};

// clientData
export const setClientData = (key, value) => (dispatch) => {
  if (isObjKey(key)) {
    dispatch({ type: SET_CLIENT_DATA, payload: { key, data: value } });
  } else {
    console.error(`VM2: cannot set value ${value} to key:${key} - wrong key`);
  }
};

export const deleteClientData = (keyList) => (dispatch, getState) => {
  if (!isArr(keyList)) return;

  const clientDataNew = keyList.reduce((acc, key) => {
    const { [key]: unused, ...restClientData } = acc;
    return restClientData;
  }, getState().appState.clientData ?? {});

  dispatch({ type: MANUAL_UPDATE_CLIENT_DATA, payload: clientDataNew });
};

export const appendToClientData = (key, data) => (dispatch, getState) => {
  if (isObjKey(key)) {
    const clientDataVal = getState().appState.clientData?.[key] ?? [];
    dispatch(setClientData(key, [...clientDataVal, ...data]));
  } else {
    console.error(`VM2: cannot delete clientData at key:${key} - wrong key`);
  }
};

export const userAuthLoadingStart = () => ({
  type: USER_AUTH_LOADING_START
});

export const userAuthLoadingEnd = () => ({
  type: USER_AUTH_LOADING_END
});

export const setMyNetworking = (key, val) => (dispatch, getState) => {
  const { myNetworking = {} } = getState().appState ?? {};
  const myNetworkingNew = {
    ...myNetworking,
    [key]: val
  };
  dispatch({ type: SET_MY_NETWORKING, payload: myNetworkingNew });
};

export const forceCloseSplashScreen = () => ({
  type: FORCE_CLOSE_SPLASH_SCREEN
});

export const setLayer = (val) => ({ type: SET_LAYER, payload: val });
