import { Loading } from '../../../../components';
import ComponentsArrRenderer from '../../../../components/core/ComponentsArrRenderer';
import { useMemberValid } from '../../../../shared/m-anage';
import { emptyArray } from '../../../../shared/misc';
import { getAddonById, withIsActiveGuard, } from '../../utils';
import { ADDON_ID } from '../model/constants';
import { useCanAccessUrl } from '../model/hooks';

const RestrictedAccess = (props) => {
  const { components = emptyArray } = getAddonById(ADDON_ID);

  return (
    <ComponentsArrRenderer
      {...props}
      components={components}
      parent={['PAGE']}
    />
  );
};

const MembershipOnlyGuard = ({ children, url, ...restProps }) => {
  const canAccessUrl = useCanAccessUrl();
  const memberValid = useMemberValid();

  if (canAccessUrl(url)) return children;
  if (memberValid.isLoading) return <Loading {...restProps} />;

  return <RestrictedAccess {...restProps} />;
};

export default withIsActiveGuard(ADDON_ID)(MembershipOnlyGuard);
