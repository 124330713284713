import { emptyArray } from '../../../../shared/misc';
import { getAddonById, isActiveById } from '../../utils';
import { ADDON_ID } from './constants';

export const isActive = () => isActiveById(ADDON_ID);
export const getModalName = () => getAddonById(ADDON_ID).modalName;
export const getBlurMode = () => getAddonById(ADDON_ID).blurMode;

export const checkIfUrlIsRestricted = (url) => {
  const { urlsForMembersOnly = emptyArray } = getAddonById(ADDON_ID);

  return urlsForMembersOnly.some(({ pageUrl, isExactPath }) => (isExactPath
    ? pageUrl === url
    : new RegExp(pageUrl, 'i').test(url)));
};
