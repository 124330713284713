export const isStr = (v) => typeof v === 'string'
  || Object.prototype.toString.call(v) === '[object String]';

export const isNumber = (v) => typeof v === 'number'
  || Object.prototype.toString.call(v) === '[object Number]';

export const isObj = (v) => Object.prototype.toString.call(v) === '[object Object]';
export const isBoolean = (v) => Object.prototype.toString.call(v) === '[object Boolean]';
export const isFunction = (v) => Object.prototype.toString.call(v) === '[object Function]';

export const isArr = Array.isArray;

export const isDate = (v) => !Number.isNaN(Date.parse(v));

// To catch a date it is imposible to have function that covers
// all possible strings. Here we have to enumerate formates we use.
const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})$/;
export const isDateStr = (v) => isoDateRegex.test(v);

const hexColorRegex = /^#[0-9a-fA-F]{3,8}$/i;
export const isHexColor = (v) => hexColorRegex.test(v);

export const isNullish = (v) => v === null || v === undefined;
export const isDefined = (v) => v !== undefined;
export const isUndefined = (v) => v === undefined;
