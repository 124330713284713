import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../components';
import storageAndCookie from '../global-utils/storage-and-cookies';
import { logoutUser } from '../global-state/redux/actions';

const LogoutPage = () => {
  const { redirectAfterLogout } = useSelector((state) => state.appState?.settings?.appSettings) ?? {};
  const location = useLocation();
  const dispatch = useDispatch();
  const prevLoacation = redirectAfterLogout ?? storageAndCookie.get('previousLocation') ?? '/';
  const params = new URLSearchParams(location.search);
  const externalUrl = params.get('externalUrl');
  const decodedExternalUrl = externalUrl
    ? decodeURIComponent(externalUrl)
    : null;

  useEffect(() => {
    dispatch(logoutUser());

    if (decodedExternalUrl) {
      window.location.href = decodedExternalUrl;
    } else {
      window.location = prevLoacation;
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 2
      }}
    >
      <Loading />
    </div>
  );
};

export default LogoutPage;
