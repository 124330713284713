export default (v) => ({
  passThroughInAppContainer: v.enableInAppContainer === true, // default false
  passThroughInPreview: v.enableInPreview === true, // default false
  passThroughUrl: v.passThroughUrl || '^/auth/login',
  passThroughStartsAt: Date.parse(v.passThroughStartsAt) || Infinity,
  passThroughEndsAt: Date.parse(v.passThroughEndsAt) || Infinity,
  // we need to test for empty string, otherwise after clearing
  // string input, we would pass-through all with empty string
  // in ticket type name.
  passThroughTicketTypeName: v.passThroughTicketTypeName || null,
  components: v.components ?? [
    {
      componentName: 'JmeText',
      text: 'This content is on hold.'
    }
  ]
});
