import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { registerLocale } from 'react-datepicker';
import { enUS, de, fr } from 'date-fns/locale';
import useShallowEqualSelector from './components/core/useShallowEqualSelector';
import { prepareApp } from './global-state/redux/actions';
import { Loading } from './components';
import FullApp from './FullApp';
import IframeCommunicator from './IframeCommunicator';
import { getElementByClassName, isInsideAppContainer } from './global-utils/vmFunctions/others';
import { getIsAdminPreview } from './shared/admin-preview';
import { emptyNull } from './shared/misc';
import {
  getBlurMode as getBlurModeMembershipOnly,
  isActive as isActiveMembershipOnly,
  getModalName as getModalNameMembershipOnly
} from './features/addon/membership-only';
import useBreakpointChange from './global-state/redux/hooks/useBreakpointChange';

const App = () => {
  const {
    isReady, isCriticalError, settings, clientData
  } = useShallowEqualSelector((state) => state.appState);
  const { loggedInOnly } = settings ?? emptyNull;
  const favicon = settings?.appSettings?.favicon;
  const dispatch = useDispatch();
  useBreakpointChange();

  useEffect(() => {
    const root = document.getElementById('vmRevolutionRoot');
    if (root && isInsideAppContainer()) {
      root.classList.add('appContainer');
    }
  }, []);

  useEffect(() => {
    dispatch(prepareApp());
  }, [dispatch]);

  useEffect(() => {
    // we are blurring the background of the login/membership modal
    const containerDiv = getElementByClassName('appWrapper-wrapper') ?? {};
    if (loggedInOnly?.blurMode || getBlurModeMembershipOnly()) {
      if (
        (loggedInOnly?.isActive
          && loggedInOnly?.blurMode
          && clientData?.[loggedInOnly?.modalName])
        || (isActiveMembershipOnly
          && getBlurModeMembershipOnly()
          && clientData?.[getModalNameMembershipOnly()])
      ) {
        containerDiv?.classList?.add('blur-mode');
      } else {
        containerDiv?.classList?.remove('blur-mode');
      }
    }
  }, [
    clientData?.[loggedInOnly?.modalName],
    clientData?.[getModalNameMembershipOnly()]
  ]);

  const handleFavicon = () => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = favicon;
  };
  useEffect(() => {
    if (isReady) {
      handleFavicon();

      // Register locale for date-fns (https://github.com/Hacker0x01/react-datepicker):
      // At the time of writing used in InputUrlSearchParamDate.
      // Note, that the el.code is a string (check doc for date-fns) and this string
      // has to be paused in the appSettings.locale.currentLocale, otherwise localization will
      // not be found by DatePicker.
      [enUS, de, fr].forEach((el) => {
        registerLocale(el.code, el);
      });
    }
  }, [isReady]);

  if (isCriticalError) {
    return (
      <div className="alert alert-danger" role="alert">
        ERROR GETTING SETTINGS SCREEN...
      </div>
    );
  }

  if (!isReady) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          height: '100vh',
          overflowX: 'hidden'
        }}
      >
        {getIsAdminPreview() && <IframeCommunicator isInit />}
        <Loading />
      </div>
    );
  }
  return <FullApp />;
};

export default App;
