// Here is the state of our app
import { vmTreeSet } from '../../../global-utils/vmTree';
import getDeviceConfig from '../../../global-utils/deviceConfig';
import {
  APP_MOBILE_MENU_OPEN,
  APP_MOBILE_MENU_CLOSE,
  APP_MOBILE_MENU_TOGGLE,
  APP_TOPNAV_MENU_OPEN,
  APP_TOPNAV_MENU_CLOSE,
  APP_SET_READY,
  APP_SET_READY_ERROR,
  APP_SET_CORE,
  APP_CHANGE_PAGE,
  APP_CHANGE_BREAKPOINT,
  SET_CURRENT_LOCATION,
  SET_TEST_FLIGHT_PAGE,
  SET_TEST_FLIGHT_PAGE_PARAMS,
  SET_TEST_FLIGHT_COMPONENTS_BUILDING,
  SET_GRID_WITH_SIDE_PREVIEW,
  SET_CLIENT_DATA,
  MANUAL_UPDATE_CLIENT_DATA,
  USER_AUTH_LOADING_END,
  USER_AUTH_LOADING_START,
  SET_USER_IS_ONLINE,
  SET_USER_IS_OFFLINE,
  SET_MY_NETWORKING,
  FORCE_CLOSE_SPLASH_SCREEN,
  SET_LAYER,
  SET_VOUCHER
} from '../constants';
import { SET_USER_PROFILE_DATA } from '../../../modules/user-profile-data/constants';
import { SET_NETWORKING_WIDGET } from '../../../modules/networking-widget';
import {
  SET_ACCESS_CONTROL,
  MODULE_NAME as ACCESS_CONTROL
} from '../../../modules/access-control';
import {
  SET_HEAD_TAGS,
  MODULE_NAME as HEAD_TAGS,
  reducer as headTagsReducer
} from '../../../modules/head-tags';
import {
  SET_MEMBERSHIP_OVERVIEW,
  MODULE_NAME as MEMBERSHIP_OVERVIEW
} from '../../../modules/membership-overview';

export const initialData = {
  isOnline: true,
  isMenuOpen: false,
  topNavMenuAnchor: null,
  isReady: false,
  isCriticalError: false,
  allCompsToCore: [],
  core: undefined, // will be an object of core settings
  settings: undefined, // will be the settings of our app
  currentPage: {
    // Current page info
    title: null,
    pageName: null
  },
  currentBreakpoint: getDeviceConfig(window.innerWidth),
  currentAppUrl: '',
  testFlightPageInfo: null,
  testFlightPageParams: [],
  testFlightComponentsBuilding: null,
  gridWithSidePreview: {
    isActive: false,
    content: {}
  },
  clientData: {},
  isAuthLoading: null, // TODO: how not to make double API calls?
  currentLayer: 'VMX',
  voucher: localStorage.getItem('mEventsVoucher') || ''
};

const apiReducer = (state = initialData, action) => {
  switch (action.type) {
    case SET_USER_IS_ONLINE:
      return {
        ...state,
        isOnline: true
      };
    case SET_USER_IS_OFFLINE:
      return {
        ...state,
        isOnline: false
      };
    case SET_VOUCHER:
      return {
        ...state,
        voucher: action.payload
      };
    case SET_TEST_FLIGHT_PAGE:
      return {
        ...state,
        testFlightPageInfo: action.payload
      };
    case SET_TEST_FLIGHT_PAGE_PARAMS:
      return {
        ...state,
        testFlightPageParams: action.payload
      };
    case SET_TEST_FLIGHT_COMPONENTS_BUILDING:
      return {
        ...state,
        testFlightComponentsBuilding: action.payload
      };
    case SET_CURRENT_LOCATION:
      return {
        ...state,
        currentAppUrl: action.payload
      };
    case APP_MOBILE_MENU_OPEN:
      return {
        ...state,
        isMenuOpen: true
      };
    case APP_MOBILE_MENU_CLOSE:
      return {
        ...state,
        isMenuOpen: false
      };
    case APP_MOBILE_MENU_TOGGLE:
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen
      };
    case APP_TOPNAV_MENU_OPEN:
      return {
        ...state,
        topNavMenuAnchor: action.payload.topNavMenuAnchor
      };
    case APP_TOPNAV_MENU_CLOSE:
      return {
        ...state,
        topNavMenuAnchor: null
      };
    case APP_SET_READY:
      return {
        ...state,
        isReady: action.payload.isReady,
        settings: action.payload.settings,
        allCompsToCore: action.payload.allCompsToCore || []
      };
    case APP_SET_CORE:
      return {
        ...state,
        core: action.payload
      };
    case APP_SET_READY_ERROR:
      return {
        ...state,
        isReady: false,
        isCriticalError: true
      };
    case APP_CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.payload
      };
    case APP_CHANGE_BREAKPOINT:
      return {
        ...state,
        currentBreakpoint: action.payload
      };
    case SET_GRID_WITH_SIDE_PREVIEW:
      return {
        ...state,
        gridWithSidePreview: action.payload
      };
    case SET_CLIENT_DATA:
      return {
        ...state,
        clientData: vmTreeSet(
          state.clientData,
          [action.payload.key],
          action.payload.data
        )
      };
    case MANUAL_UPDATE_CLIENT_DATA:
      return {
        ...state,
        clientData: action.payload
      };

    case USER_AUTH_LOADING_START:
      return {
        ...state,
        isAuthLoading: true
      };
    case USER_AUTH_LOADING_END:
      return {
        ...state,
        isAuthLoading: false
      };
    case SET_MY_NETWORKING:
      return {
        ...state,
        myNetworking: action.payload
      };
    case SET_USER_PROFILE_DATA:
      return {
        ...state,
        userProfileData: action.payload
      };
    case SET_NETWORKING_WIDGET:
      return {
        ...state,
        networkingWidget: action.payload
      };
    case SET_ACCESS_CONTROL:
      return {
        ...state,
        [ACCESS_CONTROL]: action.payload
      };
    case SET_HEAD_TAGS:
      return {
        ...state,
        [HEAD_TAGS]: headTagsReducer(state[HEAD_TAGS], action.payload)
      };
    case FORCE_CLOSE_SPLASH_SCREEN:
      return {
        ...state,
        settings: {
          ...state.settings,
          splashScreen: {
            ...state.settings.splashScreen,
            forceClose: true
          }
        }
      };
    case SET_MEMBERSHIP_OVERVIEW:
      return {
        ...state,
        [MEMBERSHIP_OVERVIEW]: action.payload
      };
    case SET_LAYER:
      return {
        ...state,
        currentLayer: action.payload
      };
    default:
      return state;
  }
};

export default apiReducer;
