import { appendId, coerceToObj } from '../myNetworkingUtils';
import {
  checkApiError,
  getUrl,
  myNetworkingApiCall
} from '../myNetworkingApiUtils';
import { getEventId } from '../../core';
import { getAccessToken, getUserId } from '../../loginInfo/loginInfo';
import {
  addGrouppost,
  deleteGrouppost,
  deleteGrouppostComment,
  deletePinnedGrouppost,
  deletePinnedGrouppostComment,
  setGrouppost,
  setGrouppostByPostId,
  setGroupposts,
  setIsPinned,
  setIsUnpinned,
  setPinnedGrouppost,
  setPinnedGroupposts
} from './grouppostsOperations';
import { MY_NETWORKING_COMMENT_TYPE } from '../myNetworkingConstants';
import { apiFilesuploadItems } from '../groups/filesuploadItems/filesuploadItemsApi';
import { apiCreateQuestionSets, apiUpdateQuestionSets } from '../questionsets';
import { parsePost } from './grouppostUtils';
import { isArr } from '../../vmArray';
import { apiRequest } from '../../../api-request';
import {
  apiGetPinboardPosts,
  getPinboardId,
  PIN_BOARD_COMMENT_TYPE
} from '../pinboard';
import {
  addNewPinBoardPost,
  deletePinboardPost
} from '../pinboard/pinboardOperations';

const STORE_MASK_GROUPPOSTS_GET = 'myNetworking/grouposts/get/groupId';
const STORE_MASK_GROUPPOSTS_GET_PINNED = 'myNetworking/grouposts/getPinned/groupId';
const STORE_MASK_GROUPPOSTS_SET_PINNED = 'myNetworking/grouposts/setPinned/postId';
const STORE_MASK_GROUPPOSTS_DELETE = 'myNetworking/groupost/delete/';
const STORE_MASK_GROUPPOSTS_GET_POST = 'myNetworking/grouposts/get/postId';

const PATHNAME = '/v1/communities.json';
const getPathname = (id) => `/v1/communities/${id}.json`;

export const apiGetGrouppostByPostId = async (props) => {
  const { postId: postIdStr, ...restProps } = coerceToObj(props);
  const postId = parseInt(postIdStr, 10);

  if (!postId) return Promise.resolve(null);

  const body = {
    event_id: getEventId(),
    id: postId,
    commenttype: MY_NETWORKING_COMMENT_TYPE,
    include_children: 1,
    is_shown: 1
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: appendId(STORE_MASK_GROUPPOSTS_GET_POST, postId),
    pathname: getPathname(postId),
    body,
    ...restProps
  }).then((res) => {
    if (res.isDebounced) return; // Do not perform data updates on debounced API calls

    setGrouppostByPostId(res.data);
  });
};

export const apiGetGroupposts = async (props) => {
  const { groupId: groupIdStr, ...restProps } = coerceToObj(props);
  const groupId = parseInt(groupIdStr, 10);

  if (!groupId) return Promise.resolve(null);

  const body = {
    event_id: getEventId(),
    chatgroup_id: groupId,
    commenttype: MY_NETWORKING_COMMENT_TYPE,
    include_children: 1,
    is_shown: 1,
    use_cache: 0
    // is_pinned: 0 // this switch do not make sense
  };
  return myNetworkingApiCall({
    method: 'GET',
    storeMask: appendId(STORE_MASK_GROUPPOSTS_GET, groupId),
    pathname: PATHNAME,
    body,
    ...restProps
  }).then((res) => setGroupposts(groupId, res.data));
};

export const apiCreateCommunity = async (props) => {
  const {
    comment,
    groupId: groupIdStr,
    parentId: parentIdStr,
    isPinboard
  } = coerceToObj(props);
  const groupId = parseInt(groupIdStr, 10);
  const parentId = parseInt(parentIdStr, 10);

  if (!isPinboard && (!groupId || !comment)) {
    return Promise.reject(
      new Error('Wrong function arguments. ', { cause: { groupId, comment } })
    );
  }

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    commentid: isPinboard ? getPinboardId() : groupId,
    commenttype: isPinboard
      ? PIN_BOARD_COMMENT_TYPE
      : MY_NETWORKING_COMMENT_TYPE,
    comment
  };
  if (parentId) body.parent_id = parentId;

  return apiRequest({
    method: 'POST',
    url: getUrl({ pathname: PATHNAME }),
    body
  }).then(checkApiError);
};

const postQuestionsets = (questions) => (isArr(questions) ? apiCreateQuestionSets({ questions }) : Promise.resolve({}));

// Based on the content from API responses, enrich content with
// relevant ids of the DB entities. For the moment it is only questionsets
// that needs this processing.
const enrichWithAddOnsIds = (content) => (resQuestonsets) => {
  const contentNew = { ...content };

  // add questionsets ID (singel ID):
  if (resQuestonsets?.data?.id) {
    delete contentNew.questionsets;
    contentNew.questionsetsId = resQuestonsets.data.id;
  }

  return contentNew;
};

const createPost = ({ groupId, isPinboard }) => (content) => apiCreateCommunity({
  comment: JSON.stringify(content),
  groupId,
  isPinboard
}).then((res) => parsePost(res.data));

const updateQuestionsets = async (aPost) => {
  const questionsetsId = aPost?.comment?.body?.text?.questionsetsId;
  if (!questionsetsId) return Promise.resolve(aPost);

  return apiUpdateQuestionSets({
    questionsetsId,
    chatgroupcommentId: aPost.id
  }).then(() => aPost);
};

const postMedia = async (aPost) => {
  const { files = [], media = [] } = coerceToObj(aPost?.comment?.body?.text);
  const filesAndMedia = [...files, ...media];

  return Promise.all(
    filesAndMedia.map(({ filesuploadId }) => apiFilesuploadItems({
      filesuploadableType: 'Chatgroupcomment',
      filesuploadableId: aPost?.comment?.id,
      filesuploadId
    }))
  ).then(() => aPost);
};

const updateGroupPosts = (aPost) => {
  addGrouppost(aPost.comment.chatgroup.id, aPost);
};

export const apiCreatePost = (props) => {
  const { content, groupId, isPinboard } = coerceToObj(props);

  return postQuestionsets(content.questionsets)
    .then(enrichWithAddOnsIds(content))
    .then(createPost({ groupId, isPinboard }))
    .then(updateQuestionsets)
    .then(postMedia)
    .then(isPinboard ? addNewPinBoardPost : updateGroupPosts)
    .catch((err) => {
      console.log('Creating post failed: ', { err });
    });
};

const apiUpdateCommunity = async (props) => {
  const {
    communityId: communityIdStr,
    comment,
    ...restProps
  } = coerceToObj(props);
  const communityId = parseInt(communityIdStr, 10);

  if (!communityId) {
    return Promise.reject(
      new Error('Wrong function arguments. ', { cause: { communityId } })
    );
  }

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    comment
  };

  return apiRequest({
    method: 'PUT',
    url: getUrl({ pathname: `/v1/communities/${communityId}.json` }),
    body,
    ...restProps
  }).then(checkApiError);
};

export const apiUpdatePost = (props) => {
  const {
    content, // we save content in comment field of the community element
    postId,
    isPinboardPost,
    ...restProps
  } = coerceToObj(props);

  // Due to data mapping it can happen, that content is not stringifyable. Use try:
  try {
    const commentStr = JSON.stringify(content);
    apiUpdateCommunity({ comment: commentStr, ...restProps }).then((res) => {
      if (res.isDebounced) return; // Do not perform data updates on debounced API calls

      if (isPinboardPost) apiGetPinboardPosts({ skipDebounce: true });
      const groupId = res.data.comment?.chatgroup?.id;
      if (groupId) apiGetGroupposts({ groupId, skipDebounce: true });
      // If groupPost is provided, then we are on the post page an we need to refresh data:
      if (postId) apiGetGrouppostByPostId({ postId, refreshRate: 1000 });
    });
  } catch (er) {
    // we do not serve error yet
  }
};

export const apiCreateComment = (props) => apiCreateCommunity(props)
  .then((res) => {
    const { isPinboard, postId } = props;
    // update group post. Post could be in pinned posts, regular posts or both:
    if (isPinboard) {
      apiGetPinboardPosts({ skipDebounce: true });
      if (postId) apiGetGrouppostByPostId({ postId, skipDebounce: true });
    } else {
      setGrouppost(res.data);
      setPinnedGrouppost(res.data);
      setGrouppostByPostId(res.data);
    }
  })
  .catch((e) => {
    console.error('Creating Comment failed. Cause:', e.cause);
  });

export const apiDeletePost = async (props) => {
  const {
    postId: postIdStr,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  } = coerceToObj(props);
  const postId = parseInt(postIdStr, 10);

  if (!postId) return Promise.resolve(null);

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    is_hidden: 1
  };

  return myNetworkingApiCall({
    method: 'PUT',
    storeMask: appendId(STORE_MASK_GROUPPOSTS_DELETE, postId),
    pathname: getPathname(postId),
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (res.isDebounced) return res; // Do not perform data updates on debounced API calls

    const id = res.data?.id;
    if (res.data?.commenttype === PIN_BOARD_COMMENT_TYPE) {
      deletePinboardPost(id);
    } else {
      // The deleted post might exist in both: pinned or regular posts or in pinboard:
      const groupId = res.data?.comment.chatgroup.id;
      deleteGrouppost(groupId, id);
      deletePinnedGrouppost(groupId, id);
    }
    return res;
  });
};

export const apiDeleteComment = async (props) => {
  const {
    postId: postIdStr,
    commentId: commentIdStr,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  } = coerceToObj(props);
  const commentId = parseInt(commentIdStr, 10);
  const postId = parseInt(postIdStr, 10);

  if (!commentId) return Promise.resolve(null);

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    is_hidden: 1
  };

  return myNetworkingApiCall({
    method: 'PUT',
    storeMask: appendId(STORE_MASK_GROUPPOSTS_DELETE, commentId),
    pathname: getPathname(commentId),
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (res.isDebounced) return res; // Do not perform data updates on debounced API calls

    const {
      comment: {
        chatgroup: { id: groupId }
      },
      id
    } = res.data;

    // State-management
    // This function can be called on two pages; news-feed and individual post page.
    // On news-feed we have to update the comments in pinned array and in normal posts array
    // On individual post page we have to refresh call.
    deleteGrouppostComment(groupId, id); // delete comment from regular post
    deletePinnedGrouppostComment(groupId, id); // delete comment from pinned posts (not necesery egsists)

    // On the news feed page, we do not need to fetch all posts.
    // also on the newsFeed page, we do not have an acces to postId.
    // ON the individual post pages, postId comes from urlParams, and on those
    // pages we also have to refresh post data:
    if (postId) apiGetGrouppostByPostId({ postId, skipDebounce: true });

    return res;
  });
};

// Pinned group posts:
export const apiGetPinnedGroupposts = (props) => {
  const {
    groupId: groupIdStr,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  } = coerceToObj(props);
  const groupId = parseInt(groupIdStr, 10);

  if (!groupId) return Promise.resolve(null);

  const body = {
    event_id: getEventId(),
    chatgroup_id: groupId,
    commenttype: MY_NETWORKING_COMMENT_TYPE,
    include_children: 1,
    is_pinned: 1,
    per_page: 3,
    is_shown: 1,
    use_cache: 0
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: appendId(STORE_MASK_GROUPPOSTS_GET_PINNED, groupId),
    pathname: PATHNAME,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (res.isDebounced) return res; // Do not perform data updates on debounced API calls

    setPinnedGroupposts(groupId, res.data);
    return res;
  });
};

const apiSetIsPinned = (props) => {
  const {
    postId: postIdStr,
    isPinned,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  } = coerceToObj(props);

  const postId = parseInt(postIdStr, 10);

  if (!postId) return Promise.resolve(null);
  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    is_pinned: isPinned ? 1 : 0,
    per_page: 3
  };

  return myNetworkingApiCall({
    method: 'PUT',
    storeMask: appendId(STORE_MASK_GROUPPOSTS_SET_PINNED, postId),
    pathname: getPathname(postId),
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  });
};

export const apiPinPost = (props) => {
  apiSetIsPinned({ ...props, skipDebounce: true, isPinned: true }).then(
    (res) => {
      if (res.isDebounced) return; // Do not perform data updates on debounced API calls
      setIsPinned(res.data);
    }
  );
};

export const apiUnpinPost = (props) => {
  apiSetIsPinned({ ...props, skipDebounce: true, isPinned: false }).then(
    (res) => {
      if (res.isDebounced) return; // Do not perform data updates on debounced API calls
      setIsUnpinned(res.data);
    }
  );
};
