import useSWR from 'swr';
import { fetchGet, PATHNAME, schema } from '../api/my-association-summary';
import { withManageConfig } from './utils';

export const useMyAssociationSummary = (params) => {
  const prms = withManageConfig(params);
  const key = schema.safeParse(prms).success ? { PATHNAME, ...prms } : null;
  const ret = useSWR(key, fetchGet(prms), {
    revalidateOnFocus: false,
    revalidateIfStale: false
  });

  return ret;
};
