/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import LZString from 'lz-string';
import PageRenderer from '../components/core/PageRenderer';
import { componentsPropType } from '../global-prop-types';
import { useApiDataHook } from '../global-hooks';
import storageAndCookie from '../global-utils/storage-and-cookies';
import { Loading } from '../components';
import { vmRefProcessorApp } from '../global-utils';

// TODO: Guy make handler list global

// TODO: Guy forward to the page renderer the query string
const RemoteComponentsPage = (props) => {
  const { pageParams, pageTypeId, pageTypeId_staging } = props;
  const location = useLocation();
  const pageUseParams = useParams();
  const [pageApiCall, setPageApiCall] = useState([]);
  const { core } = useSelector((state) => state.appState);
  const pageIdentifier = `pageInfo${pageTypeId || pageTypeId_staging}`;

  useEffect(() => {
    setPageApiCall([{
      modelName: 'medialib_webinar_page',
      uiTitle: pageIdentifier,
      url: '/v1/cms_settings.json',
      body: {
        page: 1,
        per_page: 1, // page 1 and per_page 1 means we bring the last saved settings
        cms_settingtype_id: core.server_addr.indexOf('staging') > 0 - 1 ? pageTypeId_staging : pageTypeId,
        app_id: core.app_ids,
        include_value: 1,
        timestamp: 86400000, // a day
        is_preproduction: storageAndCookie.get(`JmeENV_${core.event_id}`)
          ? 1
          : 0
      }
    }]);
  }, [pageTypeId, pageTypeId_staging]);
  const apiDataPage = useApiDataHook(pageApiCall, {
    ...pageParams,
    ...pageUseParams
  },
  location?.search
    ? Object.fromEntries(new URLSearchParams(location.search))
    : {});

  // First i need to go get the page !
  // console.log("Let's get the page !", apiDataPage);
  if (apiDataPage?.[pageIdentifier]?.isLoading) {
    return <Loading />;
  }

  const pageDataStr = LZString.decompressFromBase64(apiDataPage?.[pageIdentifier]?.data?.[0]?.value);

  if (apiDataPage?.[pageIdentifier]?.isLoading) {
    return <Loading />;
  }
  // console.log('GOT MY PAGE page !', { apiDataPage, pageDataStr });
  const pageData = pageDataStr.length > 100 ? JSON.parse(pageDataStr) : {};

  // This could have been memorized, if pageData wouldn't be parsed through JSON.parse
  // what creates new obj each render.
  const pageDataVmRefResolved = vmRefProcessorApp.resolve(pageData);

  return (
    <PageRenderer
      urlParams={{
        ...pageParams,
        ...pageUseParams
      }}
      urlSearchParams={
        location?.search
          ? Object.fromEntries(new URLSearchParams(location.search))
          : {}
      }
      {...props}
      {...pageDataVmRefResolved}
    />
  );
};

RemoteComponentsPage.propTypes = {
  components: componentsPropType,
  apiDataInfo: PropTypes.array,
  pageParams: PropTypes.shape({}),
  pageTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number,]).isRequired,
  pageTypeId_staging: PropTypes.oneOfType([PropTypes.string, PropTypes.number,]).isRequired
};

RemoteComponentsPage.defaultProps = {
  components: [],
  pageParams: {}
};
export default RemoteComponentsPage;
