/* eslint-disable prefer-destructuring */
const blockUser = async (manageConfig, options) => {
  const { apiBaseUrl, eventShort } = manageConfig;
  const { accessToken, isActive } = options;
  const { userAgent } = navigator;

  if (!isActive) throw new Error('Module is not active');
  if (!accessToken) throw new Error('Access token is missing');
  // Function to determine the device type
  const getDeviceType = (ua) => {
    if (/iPhone/.test(ua)) {
      return 'iPhone';
    } if (/iPad/.test(ua)) {
      return 'iPad';
    } if (/iPod/.test(ua)) {
      return 'iPod';
    } if (/Android/.test(ua) && !/Mobile/.test(ua)) {
      return 'Android Tablet';
    } if (/Android/.test(ua)) {
      return 'Android Phone';
    } if (/Windows Phone/.test(ua)) {
      return 'Windows Phone';
    } if (/Macintosh/.test(ua)) {
      return 'Mac';
    } if (/Windows/.test(ua)) {
      return 'Windows PC';
    } if (/Linux/.test(ua)) {
      return 'Linux PC';
    }
    return 'Other';
  };

  const getBrowserInfo = (ua) => {
    let browserName = 'Unknown';
    let fullVersion = 'Unknown';

    if (/Chrome\/(\S+)/.test(ua)) {
      browserName = 'Chrome';
      fullVersion = ua.match(/Chrome\/(\S+)/)[1];
    } else if (/Safari\/(\S+)/.test(ua) && !/Chrome/.test(ua)) {
      browserName = 'Safari';
      fullVersion = ua.match(/Version\/(\S+)/)[1];
    } else if (/Firefox\/(\S+)/.test(ua)) {
      browserName = 'Firefox';
      fullVersion = ua.match(/Firefox\/(\S+)/)[1];
    } else if (/MSIE (\d+\.\d+);/.test(ua)) {
      browserName = 'Internet Explorer';
      fullVersion = ua.match(/MSIE (\d+\.\d+);/)[1];
    } else if (/Trident\/.*rv:(\d+\.\d+)/.test(ua)) {
      browserName = 'Internet Explorer';
      fullVersion = ua.match(/Trident\/.*rv:(\d+\.\d+)/)[1];
    } else if (/Edg\/(\S+)/.test(ua)) {
      browserName = 'Microsoft Edge';
      fullVersion = ua.match(/Edg\/(\S+)/)[1];
    } else if (/Opera\/(\S+)/.test(ua)) {
      browserName = 'Opera';
      fullVersion = ua.match(/Opera\/(\S+)/)[1];
    } else if (/OPR\/(\S+)/.test(ua)) {
      browserName = 'Opera';
      fullVersion = ua.match(/OPR\/(\S+)/)[1];
    }

    return {
      name: browserName,
      version: fullVersion
    };
  };

  const deviceType = getDeviceType(userAgent);
  const browserNameAndVersion = `${getBrowserInfo(userAgent).name}${getBrowserInfo(userAgent).version}`;

  const url = `${apiBaseUrl}/api/User/BlockUser?shortname=${eventShort}&device=${deviceType}&browser=${browserNameAndVersion}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response?.status === 200) {
    return response.data;
  }
  return null;
};

export { blockUser };
