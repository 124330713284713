import { URL_PARAM_KEY_IS_ADMIN_PREVIEW } from './constants';

let isAdminPreview = false;

export const detectAdminPreview = (url) => {
  try {
    isAdminPreview = new URL(url).searchParams.has(
      URL_PARAM_KEY_IS_ADMIN_PREVIEW
    );
  } catch (e) {
    isAdminPreview = false;
  }
};

export const getIsAdminPreview = () => isAdminPreview;
