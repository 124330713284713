import {
  emptyNull,
  localStorageGetItem,
  pipe,
  takeProp
} from '../../misc';
import applyDefaults from './apply-defaults';

let state = emptyNull;

const fromStorage = (config) => {
  try {
    return pipe(
      localStorageGetItem,
      JSON.parse,
      takeProp('accessToken')
    )(`${config.instance}.user`);
  } catch {
    return null;
  }
};

export default {
  configure(config = emptyNull) {
    state = {
      ...applyDefaults(config),
      // since envJWTToken needs to be dynamically
      // fetched from storage, it has to be after apply defaults.
      get envJWTToken() {
        return fromStorage(config);
      }
    };
    return this;
  },
  get config() {
    return state;
  },
};
