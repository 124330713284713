import store from '../../global-state/redux';
import { emptyNull, noop } from '../../shared/misc';

const { getState } = store;

export const getAddonById = (id) => getState()?.appState.settings?.[id] ?? emptyNull;
export const isActiveById = (id) => !!getAddonById(id)?.isActive;

export const withIsActiveGuard = (id) => (Component) => (props) => {
  const { isActive } = getAddonById(id) ?? emptyNull;

  if (isActive) return <Component {...props} />;
  const { children } = props;
  return children;
};

export const withIsActiveGuardForHook = (id) => (hookFn) => {
  const { isActive } = getAddonById(id) ?? emptyNull;

  if (isActive) return hookFn;
  return noop;
};
