import store from '../../../../global-state/redux';
import { PIN_BOARD_ID_KEY, PIN_BOARD_KEY } from './pinboardConstants';
import { setMyNetworking } from '../../../../global-state/redux/actions';
import { getPinboard, getPinboardId } from './pinboardSelectors';
import { apiCreatePost } from '../groupposts';

const { dispatch } = store;

export const setPinboard = (pinboard) => {
  dispatch(setMyNetworking(PIN_BOARD_KEY, pinboard));
};

export const setPinboardId = (pinboardId) => {
  dispatch(setMyNetworking(PIN_BOARD_ID_KEY, pinboardId));
};

export const addNewPinBoardPost = (newPost) => {
  const pinboardPosts = getPinboard();
  setPinboard([newPost, ...pinboardPosts]);
};

export const deletePinboardPost = (id) => {
  const pinboardPosts = getPinboard();
  const otherId = (el) => el.id !== id;
  setPinboard(pinboardPosts.filter(otherId));
};

// this is used to post to pinboard only from the pinpost menu (???)
export const postToPinboard = (aPost) => {
  const { id } = aPost;
  const groupId = getPinboardId();
  const isPinboard = true;
  const content = { sharedPost: { id } };

  apiCreatePost({ content, groupId, isPinboard }).then(() => {
    addNewPinBoardPost(aPost);
  });
};
