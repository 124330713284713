import { useEffect, useState } from 'react';
import './layerControl.css';
import { useDispatch } from 'react-redux';
import useShallowEqualSelector from '../../core/useShallowEqualSelector';
import { useIsActiveByName } from '../../../modules/hooks';
import { setLayer } from '../../../global-state/redux/actions';

const adminVM2Role = 'manage_admin_user_role|MEV-App-VM2-Admin';
const adminRole = 'manage_admin_user_role|Admin';
const soRole = 'manage_admin_user_role|MEV-APP-VM2-SO';
const adminRoles = [adminVM2Role, adminRole, soRole];
// Key & values to storage + text to show
const layers = 'Layers';
export const layerStorageKey = 'layer';
export const storageValueDesktop = 'VMX';
const classValueDesktop = 'Desktop';
const storageValueApp = 'App';
const storageValueTerminal = 'Terminal';
const addLayerPrefix = (val) => `layer${val}`;
const buttonsArr = [
  { storage: storageValueApp, label: storageValueApp, icon: <i className="fa-solid fa-mobile-retro" /> },
  { storage: storageValueTerminal, label: storageValueTerminal, icon: <i className="fa-solid fa-desktop" /> },
  { storage: storageValueDesktop, label: classValueDesktop, icon: <i className="fa-solid fa-computer" /> }
];

const LayerControl = () => {
  const dispatch = useDispatch();
  const isActive = useIsActiveByName('layerControl');
  const manageAdminUserRoles = useShallowEqualSelector((state) => state?.user?.login_info?.manage_admin_user_roles);
  const isAdmin = !!(manageAdminUserRoles?.some((el) => adminRoles.includes(el.title)) && isActive);
  const [shouldShow, setShouldShow] = useState(isAdmin);
  const [showButtons, setShowButtons] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState({ label: classValueDesktop, storage: storageValueDesktop });
  const [showPopupover, setShowPopover] = useState(null);

  const handleClick = (el) => {
    setSelectedLayer({ label: el.label, storage: el.storage });
    dispatch(setLayer(el.storage));
  };

  const shouldShowPopover = (val) => (
    showPopupover === val
      ? <h6 className="layer-popup">{val}</h6>
      : null
  );

  useEffect(() => {
    setShouldShow(isAdmin);
  }, [isAdmin]);

  useEffect(() => {
    // Add class to the root element (layerApp/layerDekstop/layerTerminal)
    const root = document.getElementById('vmRevolutionRoot');
    if (root && selectedLayer) {
      root.classList.remove(...buttonsArr.map((el) => addLayerPrefix(el.label)));
      root.classList.add(addLayerPrefix(selectedLayer.label));
      // Set localStorage {layer: App/VMX/Terminal} | VMX = Desktop in PAG
      localStorage.setItem(layerStorageKey, selectedLayer.storage);
    }
  }, [selectedLayer]);

  if (!shouldShow) return null;

  return (
    <div className="layer-wrapper">
      {buttonsArr.map((el) => (
        <button
          key={el.label}
          type="button"
          className={`layer-button ${!showButtons ? 'layer-hidden' : ''} ${selectedLayer.label === el.label ? 'selected-layer' : ''}`}
          onClick={() => handleClick(el)}
          onMouseEnter={() => setShowPopover(el.label)}
          onMouseLeave={() => setShowPopover(null)}
        >
          {shouldShowPopover(el.label)}
          {el.icon}
        </button>
      ))}
      <button
        type="button"
        onClick={() => setShowButtons((prevStats) => !prevStats)}
        className="layer-button show-layers"
        onMouseEnter={() => setShowPopover(layers)}
        onMouseLeave={() => setShowPopover(null)}
      >
        {shouldShowPopover(layers)}
        {showButtons
          ? <i className="fa-solid fa-xmark" />
          : <i className="fa-solid fa-layer-group" />}

      </button>
    </div>
  );
};

export default LayerControl;
