import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Adjust based on your state management
import useTheme from '../../../global-hooks/useTheme';
import { changeCurrentBreakpoint } from '../actions';
import getDeviceConfig from '../../../global-utils/deviceConfig';

const useBreakpointChange = () => {
  const currentBreakpoint = useSelector((state) => state.appState?.currentBreakpoint);
  const theme = useTheme();
  const breakpoints = theme?.breakpoints?.values ?? {};
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      const newBreakpoint = getDeviceConfig(window.innerWidth, breakpoints || {});
      const isFullScreen = document.fullscreenElement || document.webkitFullscreenElement;
      if ((newBreakpoint !== currentBreakpoint) && !isFullScreen) {
        dispatch(changeCurrentBreakpoint(newBreakpoint));
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentBreakpoint]);
};

export default useBreakpointChange;
