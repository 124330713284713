import { isArr } from '../../../global-utils';
import { emptyArray } from '../../../shared/misc';

const rejectFactory = (msg, cause) => Promise.reject(new Error(`AccessControl: ${msg}`, { cause }));

// TODO: ERA here we mix access-control config with manage-config!!
// Refactor to use single manage-config parameters!
const buildUrl = (params) => {
  const {
    eventId, pathname, searchParams, apiBaseUrl
  } = params;

  const url = new URL(apiBaseUrl);
  url.pathname = pathname;
  url.search = new URLSearchParams({
    eventId,
    ...searchParams
  });

  return url;
};

const doFetch = (req) => fetch(req).then((res) => {
  if (!res.ok) return rejectFactory('HTTP error', res);
  if (!res.headers.get('content-type')?.includes('application/json')) return rejectFactory('wrong content-type', res);
  return res.json();
});

export const fetchTicket = async (params) => {
  const { accessToken } = params;

  const req = new Request(
    buildUrl({ ...params, pathname: '/api/Modules/BookedTickets' }),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );

  return doFetch(req).then((data) => {
    if (data === null) return emptyArray;
    if (!isArr(data)) return rejectFactory('unexpected data format for ticket', data);
    return data;
  });
};

export const fetchAccessMatrix = async (params) => {
  const req = new Request(
    buildUrl({
      ...params,
      pathname: '/api/modules/GetResources',
      searchParams: { setname: 'config_json' }
    })
  );

  return doFetch(req)
    .then((data) => {
      if (!isArr(data)) return rejectFactory('unexpected data format for access matrix', data);
      const ret = data.find((el) => el.resourcename === 'access_policies');
      if (!ret) return rejectFactory('no access policy found', data);
      return ret;
    })
    .then((res) => JSON.parse(res.resourcevalue));
};
