import React from 'react';

const SignalRLogoutModal = ({
  open,
  title,
  description,
  onContinue,
  onLogout,
  continueTxt = 'Continue',
  logoutTxt = 'Logout',
  backgroundImage = ''
}) => {
  if (!open) return null; // Prevent rendering when closed
  const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '999999'
    },
    modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
      padding: '20px',
      borderRadius: '10px',
      width: '100%',
      maxWidth: '350px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
    title: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    description: {
      fontSize: '14px',
      color: '#333',
      marginBottom: '15px',
    },
    actionContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '0.5rem',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #ced4da',
      color: 'white',
      borderRadius: '5px',
      backgroundColor: 'var(--vsx-color-secondary)',
      fontWeight: 700,
      padding: '0.5rem 1rem',
      fontSize: '15px',
      cursor: 'pointer',
      transition: 'background 0.3s ease',
    },
  };

  return (
    <div style={{ ...styles.overlay }}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          zIndex: -1,
        }}
      />
      <div style={styles.modalContainer}>
        <div style={styles.contentContainer}>
          <h2 style={styles.title}>{title}</h2>
          <p style={styles.description}>{description}</p>
        </div>
        <div style={styles.actionContainer}>
          <button
            type="button"
            style={styles.button}
            onClick={onContinue}
          >
            {continueTxt}
          </button>
          <button
            type="button"
            style={styles.button}
            onClick={onLogout}
          >
            {logoutTxt}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignalRLogoutModal;
