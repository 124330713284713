export default (config) => ({
  baseUrl: config.baseUrl ?? null,
  bearerToken: config.bearerToken ?? null,
  brightSpaceProfile_id: config.brightSpaceProfile_id ?? null,
  companyAccountId: config.companyAccountId ?? null,
  envAuthToken: config.envAuthToken ?? null,
  envClassicToken: config.envClassicToken ?? null,
  envClassicTokenPlain: config.envClassicTokenPlain ?? null,
  envJWTToken: config.envJWTToken ?? null,
  envJWTTokenJMEServices: config.envJWTTokenJMEServices ?? null,
  envJWTTokenLionLEADS: config.envJWTTokenLionLEADS ?? null,
  envJWTTokenLionLeads: config.envJWTTokenLionLeads ?? null,
  envJWTTokenMTalkExport: config.envJWTTokenMTalkExport ?? null,
  envJWTTokenPlain: config.envJWTTokenPlain ?? null,
  envJWTTokenPresentationAccess: config.envJWTTokenPresentationAccess ?? null,
  envJWTTokenSessionAccess: config.envJWTTokenSessionAccess ?? null,
  envJWTTokenThirdParty: config.envJWTTokenThirdParty ?? null,
  envLoginEmail: config.envLoginEmail ?? null,
  envLoginPassword: config.envLoginPassword ?? null,
  eventId: config.eventId ?? null,
  eventShort: config.eventShort ?? null,
  hostApi: config.apiBaseUrl ?? null,
  hostApiv2: config.hostApiv2 ?? null,
  hostIdentity: config.hostIdentity ?? null,
  hostOAuth: config.hostOAuth ?? null,
  oAuthClientId: config.oAuthClientId ?? null,
  oAuthClientSecret: config.oAuthClientSecret ?? null,
  oAuthScope: config.oAuthScope ?? null,
  societyShort: config.societyShort ?? null,
});
