import useSWR from 'swr';
import { fetchGet, PATHNAME, schema } from '../api/member-valid';
import { withManageConfig } from './utils';

// State here is to enable vmFunctions to check
// on the membership status.
let state;

export const useMemberValid = (params) => {
  const prms = withManageConfig(params);
  const key = schema.safeParse(prms).success ? { PATHNAME, ...prms } : null;
  const ret = useSWR(key, fetchGet(prms), {
    dedupingInterval: 60000,
    errorRetryInterval: 60000
  });
  state = ret.data;

  return ret;
};

// exported as vmFunction
export const isMemberValid = () => state;
